/* Default local dev (backend local)
export const siteUrl = "localhost:8080";
export const backendUrl = "localhost:5000";
export const celcomKushkiMerchantId = "7d0ec62a12ac4f678bb5fe4f6a5c20cd";
export const isProduction = false;
export const ssl = false;
export const enableLogs = false;
*/

/* Default server dev (backend server de desarrollo)
export const siteUrl = "tarot.arpi.ar";
export const backendUrl = "tarot-back.arpi.ar";
export const celcomKushkiMerchantId = "7d0ec62a12ac4f678bb5fe4f6a5c20cd";
export const isProduction = false;
export const ssl = true;
export const enableLogs = false;
*/

/* Default server prod (backend de celcom)
export const siteUrl = "app.divinotarot.cl";
export const backendUrl = "api.divinotarot.cl";
export const celcomKushkiMerchantId = "7d0ec62a12ac4f678bb5fe4f6a5c20cd";
export const isProduction = false;
export const ssl = true;
export const enableLogs = false;
*/

export const siteUrl = "divinotarot.com";
export const backendUrl = "api.divinotarot.com";
export const celcomKushkiMerchantId = "7d0ec62a12ac4f678bb5fe4f6a5c20cd";
export const isProduction = false;
export const ssl = true;
export const enableLogs = true;
export const codigoComercio = 597055555532;
export const urlCmsPayment = "localhost:81";

