<template>
  <!--Modal confirmacion pago-->
  <div>
    <b-modal
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      v-model="showModal"
      id="modal-confirmacion-plan-pago"
      centered
      @hide="handleHide"
    >
      <p class="my-2 text-modal">¿Estás seguro que quieres continuar?</p>

      <template
        #modal-footer="{
          /*hide*/
        }"
      >
        <b-button
          size="sm"
          class="col-12 btn-sm btn btn-modal"
          v-on:click="confirmPayment"
        >
          Pagar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  watch: {
    triggerModal: function (value) {
      if (value) this.showModal = true;
    },
  },
  props: ["triggerModal", "idTarotista"],

  data: () => {
    return {
      showModal: false,
      skipCloseAction: false,
    };
  },
  computed: {
    socket() {
      return this.$store.state.socket;
    },
  },
  methods: {
    handleHide() {
      // Verificar si el cierre debe evitar ejecutar cerrarAvisoLlamada
      if (!this.skipCloseAction) {
        this.cerrarAvisoLlamada();
      } else {
        this.skipCloseAction = false; // Resetear bandera después de evitar la acción
      }
    },
    confirmPayment() {
      this.skipCloseAction = true;
      this.$emit("confirmPayment");
      this.hideModal();
    },
    hideModal() {
      this.showModal = false;
    },
    async cerrarAvisoLlamada() {
      if (this.socket) {
        this.socket.emit('cerrarAvisoNotificacion', {
          idTarotista: this.idTarotista,
        });
      } else {
        console.error('Socket no está inicializado');
      }
    },
  },
};
</script>

<style scoped>
/deep/.close {
  background: transparent;
  border: none;
}
/deep/.modal-header {
  border-bottom: none;
}

/deep/.modal-body {
  padding: 0 50px;
}
.text-modal {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
}
.text-modal-2 {
  color: #501682;
  font-family: maven-bold;
  text-align: center;
  font-size: 18px;
}
.text-saldo {
  text-align: center;
  font-family: maven-regular;
  font-size: 20px;
}
.text-saldo span {
  font-family: maven-bold;
  color: #3b5998;
}
/deep/.modal-footer {
  border-top: none;
  padding: 10px 50px 30px 50px;
}
/deep/.btn-modal {
  background-color: #04d9b2;
  color: #fff;
  font-family: maven-bold;
  text-transform: uppercase;
  border: none;
}
</style>
