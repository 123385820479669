import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    jwt: null,
    hasRefreshToken: null,
    isLoggedIn: null,
    socket: null,
    tokenData: null,
    balance: 0,
    guestId: null,
    message: '',
    show: false,
    modalShow:false,
    modalShowNotLogin:false,
    idPay: null,
    emailGuest: '',
    tarotista: null,
    statust: {},
    pingActive: false,
    infoCredit:null,
    infoGuestCredit:null,
    infoLoginCredit: null,
    disabledPay: null,
    disableNotPresent: false,
    showModalScript: false,
  },
  mutations: {
    disableNotPresentFun(state, value){
      state.disableNotPresent = value;
    },
    captureStatusT(state, socket){
      socket.on('statusTarotReaderChange', (data)=>{
        if(data.idUser) data.userId = data.idUser;
        delete data.idUser;
        if(state.statust["t"+data.userId]){
          state.statust["t"+data.userId].status = data.status;
          state.statust["t"+data.userId].chanels = data.chanels;
        }else{
          state.statust["t"+data.userId] = data;
        }
      })
    },
    updateUserBalance(state, balance) {
      state.balance = balance;
    },
    login(state, jwt) {
      state.guestId = null;
      state.hasRefreshToken = true;
      state.jwt = jwt;
      state.tokenData = jwt_decode(jwt);
      state.isLoggedIn = true;
    },
    guestLogin(state, uuid) {
      state.hasRefreshToken = false;
      state.jwt = null;
      state.tokenData = null;
      state.isLoggedIn = false;
      state.guestId = uuid;
    },
    logout(state) {
      state.hasRefreshToken = false;
      state.jwt = null;
      state.isLoggedIn = false;
      state.balance = 0;
    },
    loginFailed(state) {
      state.hasRefreshToken = false;
      state.jwt = null;
      state.isLoggedIn = false;
      state.balance = 0;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setMessage(state,message){
      state.message = message;
    },
    setEmailGuest(state, emailGuest) {
      state.emailGuest = emailGuest;
    },
    valueTarotReader(state, tarotista){
      state.tarotista = tarotista
    },
    showModal(state, show){
      state.show = show;
    },
    setShowModalScript(state, showModalScript){
        state.showModalScript = showModalScript;
    },
    showModalDatos(state, modalShow){
      state.modalShow = modalShow;
    },
    showModalNotLogin(state, modalShowNotLogin){
      state.modalShowNotLogin = modalShowNotLogin;
    },
    setInfoCredit(state, infoCredit){
      state.infoCredit = infoCredit
    },
    setInfoGuestCredit(state, infoGuestCredit){
      state.infoGuestCredit = infoGuestCredit
    },
    setInfoLoginCredit(state, infoLoginCredit){
      state.infoLoginCredit = infoLoginCredit
    },
    setDisabledPay(state, disabledPay){
      state.disabledPay = disabledPay
    },
    
    activePing(state) {
      if(!state.pingActive) {
        setInterval( () => {

          try {
            let status = window.localStorage.getItem('ST');

            let chanels = {
              chat: this.status.chat != null ? (status.chat ? true : false) : null,
              video: this.status.video != null ? (status.video ? true : false) : null,
              voice: this.status.voice != null ? (status.voice ? true : false) : null
            };

            state.socket.emit({
              value: (status.value ? (status.value == 1 ? 1 : 0 ) : 1) == 1 ? false: true,
              chanels
            });
          } catch (error) {
            error
          }
        }, 60000 )
      }
    }
  },
  getters: {
    socket: (state) => state.socket,
    jwt: (state) => state.jwt,
    hasRefreshToken: (state) => state.hasRefreshToken,
    isLoggedIn: (state) => state.isLoggedIn,
    tokenData: (state) => state.tokenData,
    balance: (state) => state.balance,
    guestId: (state) => state.guestId,
    emailGuest: (state) => state.emailGuest
  },
});
