var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row justify-content-center mt-3 pb-5"},[_vm._m(1),(_vm.isLoading)?_c('div',{staticClass:"row justify-content-center mt-5"},[_c('Spinner')],1):_c('div',{staticClass:"col-10 content"},[_c('div',{staticClass:"row content-icon"},[(_vm.tarotista !== null)?_c('div',{staticClass:"col-lg-4 col-12 mb-lg-0 mb-3 padding-card"},[_c('Descripcion',{attrs:{"tipo":_vm.tipo,"tarotista":_vm.tarotista,"canal":_vm.canal,"isLoggedIn":_vm.isLoggedIn},on:{"durationchanged":_vm.changeMinutesCall,"priceChanged":_vm.priceChangedCall}})],1):_vm._e(),_vm._m(2),_c('div',{staticClass:"col-lg-4 col-12 mb-lg-0 mb-3 padding-card"},[(_vm.isLoggedIn)?_c('MetodoPago',{attrs:{"tipo":_vm.tipo,"existe":_vm.existe,"tarotistaId":_vm.tarotistaId,"correo":_vm.correo_user,"nombre":_vm.nombre_user,"canal":_vm.canal,"tarotista":_vm.tarotista,"duration":_vm.duration},on:{"metodoPagoChange":(newMethod) => {
              _vm.metodoPago = newMethod;
            },"registroUse":_vm.registroUse}}):_c('DatosPersonales',{attrs:{"tipo":_vm.tipo,"existe":_vm.existe,"tarotistaId":_vm.tarotistaId,"duration":_vm.duration},on:{"metodoPagoChange":(newMethod) => {
              _vm.metodoPago = newMethod;
            },"registroUseInvitado":_vm.registroUseInvitado,"valorLoginFunc":_vm.valorLoginFunc}}),(_vm.metodoPago === 'Credit' && _vm.info == 1)?_c('ModalDatos'):_vm._e()],1),_vm._m(3),_c('div',{staticClass:"col-lg-4 col-12 mb-lg-0 mb-3 padding-card"},[(_vm.metodoPago === 'Balance' && _vm.tarotista)?_c('DatosDivinoSaldo',{attrs:{"tarotista":_vm.tarotista,"minutos":_vm.duration,"canal":_vm.canal,"existe":_vm.existe,"tipo":_vm.tipo},on:{"askForConfirmPayment":_vm.askForConfirmPayment,"registroMod":_vm.registroMod}}):_vm._e(),(_vm.metodoPago === 'Credit' && _vm.isLoggedIn)?_c('DatosTarjeta',{attrs:{"cardSaved":_vm.duration == 0 ? _vm.cardSaved : false,"lastDigits":_vm.lastDigits},on:{"askForConfirmPayment":_vm.askForConfirmPayment,"tarjetaDeleted":function($event){_vm.cardSaved = false;
            _vm.lastDigits = null;}}}):_vm._e(),(_vm.metodoPago === 'Credit' && !_vm.isLoggedIn)?_c('DatosTarjetaNotLogin',{attrs:{"cardSaved":_vm.duration == 0 ? _vm.cardSaved : false,"lastDigits":_vm.lastDigits},on:{"askForConfirmPayment":_vm.askForConfirmPayment,"tarjetaDeleted":function($event){_vm.cardSaved = false;
            _vm.lastDigits = null;}}}):_vm._e(),(_vm.metodoPago === 'Comodin' && !_vm.isLoggedIn)?_c('Comodin'):_vm._e(),(_vm.metodoPago === 'Debit')?_c('DatosTarjetaDebito',{on:{"askForConfirmPayment":_vm.askForConfirmPayment}}):_vm._e(),(_vm.metodoPago === 'transbank')?_c('DatosWebPay',{attrs:{"existe":_vm.existe,"tipo":_vm.tipo,"guestId":_vm.$store.state.guestId},on:{"askForConfirmPayment":_vm.askForConfirmPayment,"registroMod":_vm.registroMod,"registroModInvitado":_vm.registroModInvitado}}):_vm._e(),(_vm.metodoPago === 'Transfer')?_c('DatosTransferencia',{attrs:{"existe":_vm.existe,"tipo":_vm.tipo},on:{"askForConfirmPayment":_vm.askForConfirmPayment,"registroMod":_vm.registroMod}}):_vm._e()],1)]),_vm._m(4)]),_c('ModalConfirmacion',{attrs:{"idTarotista":_vm.tarotistaId,"triggerModal":_vm.modalTriggered === 'modalConfirmacion'},on:{"confirmPayment":_vm.confirmPayment}}),_c('ModalEspera',{attrs:{"triggerModal":_vm.modalTriggered === 'modalEspera',"forceClose":_vm.forceClose == 'modalEspera'}}),_c('ModalPagoExitoso',{attrs:{"triggerModal":_vm.modalTriggered === 'modalPagoExitoso',"paymentMethod":_vm.metodoPago,"balance":this.$store.state.balance,"tipo":_vm.tipo}}),_c('ModalPagoFallo',{attrs:{"triggerModal":_vm.modalTriggered === 'modalPagoFallo'}}),_c('ModalDatosLlamada',{attrs:{"triggerModal":_vm.modalTriggered === 'modalDatosLlamada'},on:{"setPhoneNum":function($event){_vm.numeroTelefono = $event},"askForConfirmation":function($event){return _vm.triggerModal('modalConfirmacion')}}}),_c('ModalLlamadaConfirmada',{attrs:{"triggerModal":_vm.modalTriggered === 'modalLlamadaConfirmada',"tarotistaName":_vm.tarotista ? _vm.tarotista.alias : '',"paymentMethod":_vm.metodoPago,"balance":this.$store.state.balance,"tipo":_vm.tipo}}),_c('ModalLlamadas'),_c('ModalVideosChat'),_c('Login',{attrs:{"triggerModal":_vm.modalTriggered === 'login'},on:{"triggerModal":_vm.triggerModal}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"submenu"},[_vm._v("Inicio - Precios y planes - "),_c('span',[_vm._v("Lectura")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"title general-title"},[_vm._v("Finalizar compra")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 arrow-v"},[_c('div',{staticClass:"col-triangulo-v text-center"},[_c('div',{staticClass:"triangulo-one-v"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 arrow-v"},[_c('div',{staticClass:"col-triangulo-v text-center"},[_c('div',{staticClass:"triangulo-one-v"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center content-arrow"},[_c('div',{staticClass:"col-lg-1 col-md-2 text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-triangulo"},[_c('div',{staticClass:"triangulo-one"})])])]),_c('div',{staticClass:"col-lg-3 col-md-4 mx-lg-0 mx-md-2"}),_c('div',{staticClass:"col-lg-1 col-md-2 text-center",staticStyle:{"padding-left":"38px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-triangulo"},[_c('div',{staticClass:"triangulo-one"})])])])])
}]

export { render, staticRenderFns }