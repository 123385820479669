<template>
  <div>
    <div class="container-fluid content-header p-0">
        <div :class="{
          'header-bg fixed-top': !(cinta && isTargetUrl) || (cinta && isTargetUrl),
          'header-bg-cinta fixed-top': !cinta && isTargetUrl,
        }">
        <NavRegistrado v-if="isLoggedIn" />
        <Nav v-else />
      </div>
      <div v-if="!cinta" class="cinta-amarilla-container">
        <div class="cinta-amarilla">
          <div class="container">
            <div class="row">
              <div class="text-center d-flex align-items-center justify-content-center">
                <p class="me-3">Conoce a los tarotistas | 50% en tu primera lectura.</p>
                <button  @click="registro" class="btn btn-morado shadow-sm"> ¡Aquí! </button>
                <a @click="cerrarCinta" href="#" class="btn-cerrar"><i class="fas fa-times"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid content-header p-0">
      <div class="banner-bg">
        <Banner />
      </div>
    </div>
    <div class="container-fluid content-functionality">
      <Functionality />
    </div> 
<div class="container">
      <TopTarotista />
    </div>
     <div class="container">
      <Ambitos />
    </div>
  <div class="container-fluid content-new-tarotista p-0">
      <NewTarotista />
      <img class="img-left" src="../../assets/img/eyes.png" alt="" />
      <img class="img-bottom" src="../../assets/img/eyes.png" alt="" />
    </div>
     <div class="container-fluid content-info p-0">
      <Information />
    </div>
    
    <div class="container-fluid content-banner p-0">
      <div class="bannerFooter-bg">
        <BannerFooter />
      </div>
    </div>
    <div class="container-fluid content-footer p-0">
      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from "../nav/Nav";
import NavRegistrado from "../nav/NavRegistrado";

import Banner from "../home/BannerHeader";
import Functionality from "../home/Functionality"; 
import Ambitos from "../home/Ambitos";
 import NewTarotista from "../home/NewTarotista";
import Information from "../home/Information.vue";
import TopTarotista from "../home/TopTarotista";
import BannerFooter from "../home/BannerFooter";
import Footer from "../footer/Footer";

export default {
  name: "Layout",
  components: {
    Nav,
    NavRegistrado,
   Banner, 
    Functionality,
    Ambitos,
  NewTarotista,
      TopTarotista,
    BannerFooter,
    Footer,
    Information,
    //Login
  },
  data() {
    return {
      cinta: false,
    };
  },
  mounted() {
    if(this.$store.state.tokenData)
    if(this.$store.state.tokenData.permissions)
    if(this.$store.state.tokenData.permissions.isTarotReader){
      this.$router.push('/dashboard-tarotista');
    }
    this.showModalOnInteraction();
  },
  computed: {
    isModalVisible() {
      return this.$store.state.showModalScript; // Vincula el estado de Vuex
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isTargetUrl() {
      const targetUrls = ['/']; // Reemplaza con tus URLs específicas
      return targetUrls.includes(this.$route.path);
    }
  },
  methods: {
    showModalOnInteraction() {
      setTimeout(() => {
        if (!this.isLoggedIn && !this.isModalVisible) {
          const triggerModal = () => {
            if (typeof window.ml !== "undefined") {
              window.ml("show", "10Vk19", true);
              this.$store.commit('setShowModalScript', true);
              window.removeEventListener("mousemove", triggerModal); // Eliminar el evento después de la activación
            } else {
              console.error("La función 'ml' no está definida.");
            }
          };

          window.addEventListener("mousemove", triggerModal);
        }
      }, 5000);
    },
    cerrarCinta(){
      this.cinta = true;
    },
    registro() {
      this.$router.push('/catalogo');
    },
  },
};
</script>

<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
.content-header .header-bg {
  top: 0px;
}
.content-header .header-bg-cinta {
  top: 60px;
  background-color: #501682;
}
.cinta-amarilla-container {
  top: 0;
  position: fixed;
  z-index: 2;
  width: 100%
}
.cinta-amarilla {
  padding: 10px 0;
  background-color: #ffc209;
  font-family: maven-regular
}
.cinta-amarilla .btn-morado {
  background: #501682;
  color: #ffc30b;
  text-transform: uppercase;
  font-family: maven-bold;
  padding: 8px 50px;
  border-radius: 50px;
  font-size: 15px;
  transition: all .5s ease
}
.cinta-amarilla p {
  margin: 0
}
.cinta-amarilla .btn-cerrar {
  position: absolute;
  right: 20px;
  color: var(--bs-dark-text-emphasis);
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .cinta-amarilla p {
    font-size:.875em
  }
  .cinta-amarilla .btn-morado {
    padding: 8px 20px;
    margin-right: 0.75rem;
  }
  .cinta-amarilla .btn-cerrar {
    right: 5px;
    top: 0px;
  }
}
.content-header {
   background-color: #fff;
}

.banner-bg {
  padding-top: 128px;
  background-color: #fff;
}

.header-bg {
  background-color: #501682;
}
.content-functionality {
  background-color: #f6f6f6;
  position: relative;
}
.content-new-tarotista {
  position: relative;
  background-color: #501682;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.img-left {
    position: absolute;
    top: -95px;
    left: -90px;
    max-width: 460px;
}
.img-bottom {
  position: absolute;
  bottom: -50px;
  right: 200px;
  max-width: 200px;
}
.content-info {
  background-image: url("../../assets/img/info.png") ;
  background-repeat: no-repeat;
  background-size: cover;
   
}

.content-banner {
  background-image: url("../../assets/img/footer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #1b082d;
}

.content-footer {
  background-color: #dddddd;
}
</style>
