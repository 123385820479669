<template>
  <div class="container" v-if="isLoggedIn || idInv != null">
    <div class="row  pb-5">
      <div class="col-12 d-flex flex-column">
        <div class="card shadow order-2 order-md-1">
          <div class="card-header">
            <div class="row">
              <div class="col col-img">
                <div class="image">
                  <img :src="avatarUser" alt="" />
                </div>
              </div>
              <div class="col m-auto">
                <h5 class="name-tarotista">{{ nameUser }}</h5>
              </div>
              <div class="col m-auto text-end time align-self-end">
                <span>{{ dosDigitos(minutosMostrar) }}</span
                >:<span>{{ dosDigitos(segundosMostrar) }}</span>
              </div>
            </div>
          </div>
          <div class="card-body" ref="containerChat">
            <div class="row" v-for="(message, index) in messages" :key="index">
              <div class="col s12" v-if="message.type === 'REMOTE'">
                <div>
                  <div class="chat chat-you shadow">
                    <span class="date"
                      >{{ nameUser }} - {{ message.time }}</span
                    >
                    {{ message.text }}
                  </div>
                </div>
              </div>

              <div class="col s12" v-else>
                <div>
                  <div class="chat chat-me shadow">
                    <span class="date">Yo - {{ message.time }}</span>
                    {{ message.text }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!tarotConected" style="text-align: center">
              <br /><br />
              <h5>{{lostPartener ? (this.$store.state.tokenData.permissions.isTarotReader ? 'El cliente perdió la conexión':'El tarotista perdió la conexión') : 'Cargando la Sala'}}</h5>
              <br />
              <div
                style="max-width: 45px; margin-left: auto; margin-right: auto"
              >
                <Spinner />
              </div>
            </div>

            <div class="row" v-if="this.end">
              <div class="col s12">
                <div style="text-align: center">
                  <hr />
                  Chat Finalizado
                  <hr />
                </div>
              </div>
            </div>

            <div class="row"></div>
            <div class="col-12"></div>
          </div>
          <!--           <button @click="scrollToBottom">scroll to bottom</button> -->

          <div class="card-footer">
            <div class="row" v-if="tarotConected">
              <div class="col input-chat">
                <input
                  class="text-chat"
                  v-model="textBox"
                  @keyup.enter="sendMessage()"
                  type="text"
                  placeholder="Mensaje..."
                />
              </div>
              <div class="col container-btn">
                <!--  <label class="btn-shadow btn-send">
                  <i class="fas fa-paperclip"></i>
                  <input
                    type="file"
                    class="input-file"
                    id="sf"
                    @change="upfile"
                  />
                </label>
 -->
                <label @click="sendMessage()" class="btn-shadow btn-submit">
                  <i class="fas fa-paper-plane"></i>
                  <input class="input-submit" type="submit" id="textChat" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-md-5 py-5 text-center order-1 order-md-2">
          <!--         <button
            @click="endRoom"
            v-b-modal.modal-finalizar
            class="btn btn-close-chat"
          >
            Finalizar chat
          </button> -->

          <button @click="endRoom" class="btn btn-close-chat">
            Finalizar chat
          </button>
          <ModalFinalizar />
          <!-- cambios
          <ModalFinChat :endChat="endChat" /> -->

          <!-- <button @click="leaveChat()" class="btn btn-close-chat">
            Finalizar chat
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row row-content" v-else>
    <LoginEspera
        :url="url"
        v-on:triggerModal="triggerModal"
        :triggerModal="modalTriggered === 'login'"/>
  </div>
</template>
<script>
import ModalFinalizar from "./modals/ModalLlamadaFinalizada.vue";
import Spinner from "../loadingspinner/Spinner.vue";
import { enableLogs, celcomKushkiMerchantId, isProduction } from '../../config';
import axiosClient from '@/config/axiosClient';
import LoginEspera from "@/components/login/LoginEspera.vue";
const { Kushki } = require('@kushki/js')

/* import ModalFinChat from "./modals/ModalFinLlamada.vue"; */
export default {
  data: () => {
    return {
      modalTriggered: null,
      idInv: null,
      roomId: null,
      messages: [],
      textBox: "",
      avatarUser: "/img/avatar.jpg",
      nameUser: "Cargando...",
      end: false,
      segundos: 0,
      minutos: 0,
      tiempo: null,
      tarotConected: false,
      /* endChat: false, */
      otherRoom: null,
      duracionTotal: '-',
      lostPartener: false,
      closeRoom: false,
      modalShow: false,
      id_invitado: null,

      tiempoMostrar: null,
      segundosMostrar: 0,
      minutosMostrar: 0,
    };
  },
  components: {
    LoginEspera,
    ModalFinalizar,
    Spinner,
    
    /* ModalFinChat, */
  },
  watch: {
    socket: async function (socket) {
      if (socket === null || socket === undefined) return;
      if (!this.isLoggedIn && this.idInv == null){
        return this.triggerModal('login')
      }else {
        await this.startSocket();
      }
    },
    startRoomFlag: function (flag) {
      if (flag) this.startRoom();
    },
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    },
    socket() {
      return this.$store.state.socket;
    },
    startRoomFlag() {
      return (
        this.$store.state.socket != null &&
        this.$store.state.tokenData != null &&
        this.$store.state.tokenData.permissions.isTarotReader
      );
    },
  },
  mounted: function () {
    //if (this.$store.state.tokenData.permissions.isTarotReader) {
    //  this.cronometro();
    //}
  },
  created() {
     this.$store.commit("showModal", false);
    this.roomId = this.$route.query.roomId;
    // Make the socket listen start
    this.idInv = this.$route.query.idInv;
    if (!this.isLoggedIn && this.idInv == null){
      return this.triggerModal('login')
    }else {
      if (this.socket) {
        this.startSocket();
      }
      if (this.startRoomFlag) {
        this.startRoom();
      }
    }
  },
  methods: {
    async logBack() {
      // Emitir evento de log al backend
      this.socket.emit('log_event', {
        message: 'initControl triggered',
        timestamp: new Date().toISOString(),
        roomId: this.roomId, // Puedes pasar más detalles si es necesario
        connected: this.tarotConected,
      });
    },
    async triggerModal(modal) {
      console.log(`Showing ${modal} modal`)
      switch (modal) {
        case 'login':
          this.modalTriggered = 'login'
          break
        case 'register':
          this.modalTriggered = 'register'
          break
        case 'password':
          this.modalTriggered = 'password'
          break
        case 'register-success':
          this.modalTriggered = 'register-success'
          break
      }
      await this.$nextTick()
      this.modalTriggered = null
    },
    async getInInvitado(){
      const usado = await axiosClient.post(
        '/tarot-calendly/post-registro-use/getIdInvitado',
        {
          room_uuid: this.roomId,
        },
      );

      if (usado.data.id_invitado != null) {
        this.id_invitado = usado.data.id_invitado;
      }
    },
    startRoom() {
      this.logBack();
     if(enableLogs) console.log("Starting room");
      this.socket.emit("start-room", this.roomId);
    },
    clearEventsSocket() {
      delete this.socket._callbacks['$initControl'];
      delete this.socket._callbacks['$lost-partener'];
      delete this.socket._callbacks['$reconect-partener'];
      delete this.socket._callbacks['$prox-finish'];
      delete this.socket._callbacks['$end-room'];
      delete this.socket._callbacks['$clock'];
      delete this.socket._callbacks['$waitpartener'];
    },
    endRoom() {
      this.closeRoom = true;
      this.socket.emit("end-room", this.roomId);
      
      /* alert(this.$store.state.tokenData.permissions.isTarotReader) */
      //this.socket.emit(this.roomId, "@@END@@");
      /*if (this.$store.state.tokenData.permissions.isTarotReader) {
        this.socket.emit("change-state", "AVAILABLE");
      }
      this.$store.commit("setMessage", "¡El chat ha finalizado!");
        this.$router.push("/");*/
      /* const goToHome = () => {
        if (
          this.$store.state.tokenData &&
          this.$store.state.tokenData.permissions.isTarotReader
        ) {
          this.socket.emit("change-state", "AVAILABLE");
          this.endChat = true;
          this.$router.push("/");
        }

        this.$store.commit("setMessage", "¡El chat ha finalizado!");
        this.$router.push("/");
      }; */
    },

    async startSocket() {
      await this.logBack();
      if (!this.isLoggedIn && this.idInv != null) {
        await this.getInInvitado();
        if (this.id_invitado !== null) localStorage.setItem("guestId", this.id_invitado)
        await this.$store.commit('guestLogin', this.id_invitado)
      }
     if(enableLogs) console.log("Connecting to socket server...");
      let socket = this.$store.state.socket;
      if (socket == null) return;
      const goToHome = () => {
        if (
          this.$store.state.tokenData &&
          this.$store.state.tokenData.permissions.isTarotReader
        ) {
          this.socket.emit("change-state", "AVAILABLE");
          this.$router.push("/");
          this.$store.commit("setMessage", "¡El chat ha finalizado!");
          this.modalShow = true
          goToHome();
          
        }
      };
     if(enableLogs) console.log(goToHome);
      /*socket.emit("request-tarot-reader", this.roomId, "CHAT", (response) => {
        if(enableLogs) console.log(response);
        if (response.code == 1) {
          this.endChat = true;
          goToHome();
        }
      });*/

      socket.emit("join-chat-room", this.roomId, (response) => {
       if(enableLogs) console.log(response);

        if (response.code == 1) {
          this.$store.commit(
            "setMessage",
            "Debe estar logueado para ingresar a la sala de chat"
          );
          this.$router.push("/");
          goToHome();
        }

        if (response.code === 2) {
          if(this.$store.state.tokenData) if(this.$store.state.tokenData.permissions) if (this.$store.state.tokenData.permissions.isTarotReader) {
            this.socket.emit(this.roomId, "@@INIT@@");
            this.cronometro();
            this.startRoom();
          }
          this.tarotConected =
            this.tarotConected ||
            (this.$store.state.tokenData ? (this.$store.state.tokenData.permissions ? this.$store.state.tokenData.permissions.isTarotReader : false) : false);
          this.nameUser = response.otherUser.nombre;
          this.avatarUser = response.otherUser.avatar;
          /*if (!this.$store.state.tokenData.permissions.isTarotReader)
          this.$store.commit(
            "setMessage",
            "Hola " +
              this.$store.state.tokenData.personName +
              ", bienvenido a tu lectura de tarot con " +
              response.otherUser.nombre
          );*/
          //alert('Hola '+this.$store.state.tokenData.personName+', bienvenido a tu lectura de tarot con '+response.otherUser.nombre)
        }
        this.socketJoined = response.code === 2;
      });

      socket.on(this.roomId, (message) => {
       if(enableLogs) console.log(message);
        this.recieveMessage(message.message);
      });

      this.socket.on('initControl',(timeObject)=>{
        console.log(JSON.stringify(timeObject));
        // Extraer las propiedades de tiempo del objeto
        const inicio = new Date(timeObject.inicio); // Convertir a objeto Date
        const fin = new Date(timeObject.fin); // Convertir a objeto Date

        // Calcular la duración en minutos
        const duracion = (fin - inicio) / 1000 / 60; // Convertir a minutos

        console.log(`Duración total de la llamada: ${duracion} minutos`);
        this.minutosMostrar = duracion;
        this.cronometro();
        this.cronometroMostrar();
        this.tarotConected = true;
      });
      this.socket.on(this.roomId+'-chargeToken',(idSusc)=>{
        let kushki = new Kushki({
            merchantId: celcomKushkiMerchantId,
            inTestEnvironment: !isProduction,
            regional: false,
        })

        kushki.requestTokenCharge({
            subscriptionId: idSusc
        }, async (response2) => {
            if(response2.token){
              this.socket.emit(this.roomId+'-chargeToken', response2.token);
            }
        });
      });
      this.socket.on('waitpartener',()=>{
        this.tarotConected = false;
        this.lostPartener = true;
      });
      this.socket.on('clock',(obj)=>{
        this.minutos = obj.minTransc;
        this.segundos = obj.segTransc;
        this.duracionTotal = obj.duracionTotal;
        console.log(this.duracionTotal);
        console.log(this.minutos);
        this.minutosMostrar = (this.duracionTotal/60) - (this.minutos + 1);
        this.segundosMostrar = 60 - this.segundos;
      })

      socket.on('lost-partener', () => {
        this.tarotConected = false;
        this.lostPartener = true;
      });
      socket.on('reconect-partener', () => {
        this.tarotConected = true;
        this.lostPartener = false;
      });
      socket.on('prox-finish', () => {
        this.$store.commit("setMessage", "¡El chat esta por finalizar! quedan 2 minutos");
      });
      socket.on('end-room', (manual) => {
        this.clearEventsSocket();
        if(this.$store.state.tokenData)
        if(this.$store.state.tokenData.permissions)
        if (this.$store.state.tokenData.permissions.isTarotReader) {
          this.socket.emit("change-state", "AVAILABLE");
        }

        this.$store.commit("setMessage", manual ? (!this.closeRoom ? "El otro participante corto la sala":'Finalizaste la sala') : "¡El chat ha finalizado!");
        this.end = true;

        this.$router.push("/");
        
        
      });
      /*socket.on('otherRoomWindow',()=>{
        this.$store.commit("setMessage", "Ingresaron con tu usuario desde otra ventana");
        setTimeout(() => {
            window.location.href = 'https://www.celcom.cl/';
        }, 3000);
      })*/

      socket.on('no-ingreso', () => {
        this.$router.push("/");
        this.$store.commit("setMessage", "El tarotista no se conecto, comunicate con el equipo de divino tarot");
      });
      //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
      /*socket.on("chat-room-ended", (roomId) => {
        this.end = true;
       if(enableLogs) console.log("roomId: ", roomId);
        if (roomId == this.roomId) {
          this.endChat = true;
          goToHome();
        }
      }); */
      //! @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

      if (
        this.$store.state.tokenData &&
        this.$store.state.tokenData.permissions.isTarotReader
      ) {
        this.startRoom();
      }
    },
    sendMessage() {
      if (!this.end) {
        if (this.textBox == "") return;
        let message = this.textBox;
        this.messages.push({
          type: "LOCAL",
          text: message,
          time: new Date().toLocaleTimeString().substr(0, 5),
        });

        let socket = this.$store.state.socket;

        socket.emit(this.roomId, message);

        this.textBox = "";
        this.scrollToBottom();
      }
    },
    recieveMessage(message) {
     if(enableLogs) console.log(message);
      if (message == "@@INIT@@") {
        //this.cronometro();
        //this.tarotConected = true;
      } else if (message == "@@END@@") {
        // if (this.$store.state.tokenData.permissions.isTarotReader) {
        //   this.socket.emit("change-state", "AVAILABLE");
        // }
        // this.$store.commit("setMessage", "¡El chat ha finalizado!");
        // this.end = true;
        // this.$router.push("/");
      } else {
        if (!this.end) {
          this.messages.push({
            type: "REMOTE",
            text: message,
            time: new Date().toLocaleTimeString().substr(0, 5),
          });
          this.scrollToBottom();
        }
      }
    },
    leaveChat() {
      if (
        this.$store.state.tokenData &&
        this.$store.state.tokenData.permissions.isTarotReader
      )
        this.socket.emit("change-state", "AVAILABLE");
      this.$router.push("/");
    },
    /* scrollToBottom: function() {
       this.$chatHistory.scrollTop(this.$chatHistory[0].scrollHeight);
    }, */
    scrollToBottom() {
      setTimeout(() => {
        const containerChat = this.$refs.containerChat;
        containerChat.scrollTop = containerChat.scrollHeight;
      }, 1);
    },
    upfile() {
      window.filechat();
    },

    cronometro() {
      if (this.tiempo == null) {
        this.tiempo = window.setInterval(this.intervalo, 1000);
      }
    },

    intervalo() {
      this.segundos++;
      if (this.segundos == 60) {
        this.segundos = 0;
        this.minutos++;

        if (this.minutos == 60) {
          this.minutos = 0;
        }
      }
    },

    // Conteo descendente
    cronometroMostrar() {
      if (this.tiempoMostrar == null) {
        this.tiempoMostrar = window.setInterval(this.intervaloMostrar, 1000);
      }
    },
    intervaloMostrar() {
      if (this.segundosMostrar > 0 || this.minutosMostrar > 0) {
        if (this.segundosMostrar === 0) {
          if (this.minutosMostrar > 0) {
            this.segundosMostrar = 59;
            this.minutosMostrar--;
          }
        } else {
          this.segundosMostrar--;
        }
      }/* else {
        // Detener el conteo regresivo cuando llegue a 0
        this.detenerCronometroMostrar();
        alert("¡El tiempo ha terminado!");
      }*/
    },
    detenerCronometroMostrar() {
      if (this.tiempoMostrar !== null) {
        clearInterval(this.tiempoMostrar);
        this.tiempoMostrar = null;
      }
    },

    dosDigitos(num) {
      var res = num.toString();
      if (res.length == 1) {
        res = "0" + res;
      }
      return res;
    },
  },
};
</script>
<style scoped>
.content-body{
 padding-top: 50px !important;
}
.sk-chase {
  margin: unset !important;
}
.title {
  color: #501682;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: bold;
}

.card-header,
.card-footer {
  background-color: #fff;
}
.col-img {
  max-width: 60px;
  padding-right: 0;
}
.image {
  width: 48px;
  height: 48px;
}
.image img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}
.card-body {
  background-color: #f9fafb;
  font-family: maven-regular;
  overflow: auto;
  min-height: 500px;
  max-height: 500px;
}
/*    SCROLL    */
/* width */
.card-body::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* Handle */
.card-body::-webkit-scrollbar-thumb {
  background: #cfcfcf;
  border-radius: 10px;
}
/* Handle on hover */
.card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/**/
.chat {
  border-radius: 5px;
  width: max-content;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;
  margin-top: 26px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  max-width: 47%;
  min-width: 200px;/* en determinados mensajes se rompe la estetica si tiene 150px */
}
.chat-me {
  margin-left: auto;
  background-color: #d9c7e9;
  border-radius: 20px 20px 0 20px;
  padding: 6px 12px;
  margin-bottom: 15px;
}
.chat-you {
  background-color: #eef0f8;
  border-radius: 20px 20px 20px 0;
  padding: 6px 12px;
  margin-bottom: 15px;
}
.date {
  position: absolute;
  left: 3px;
  top: -22px;
  font-family: maven-bold;
  color: #707070;
  font-size: 13px;
  margin-bottom: 10px;
}

.btn-submit {
  background-color: #dddddd;
  color: #707070;
  height: 30px !important;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  margin: auto;
}
.btn-submit i {
  margin-left: 13px;
}
.btn-send {
  color: #707070;
  height: 24px !important;
  width: 24px;
  align-items: center;
  display: -webkit-inline-box;
  display: inline-flex;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  margin: 0 5px;
}
.input-file {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.input-submit {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.text-chat {
  font-family: maven-regular;
}
.container-btn {
  max-width: 90px;
  text-align: end;
}

.input-chat input {
  width: 100%;
  border: none;
}
.input-chat input:focus {
  outline: none;
}

.btn-close-chat {
  background: #ea0000;
  color: #ffffff;
  font-family: maven-bold;
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 10px;
}
.btn-close-chat:hover {
  background: #dba501;
  color: #650bbf;
}

.name-tarotista {
  font-family: maven-semibold;
  font-size: 16px;
  margin: 0;
  color: #707070;
}
.time,
.time span {
  font-family: maven-medium;
  color: #999;
}
</style>
