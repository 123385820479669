<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="submenu">Inicio - Precios y planes - <span>Lectura</span></p>
      </div>
    </div>
    <div class="row justify-content-center mt-3 pb-5">
      <div class="col-12">
        <h5 class="title general-title">Finalizar compra</h5>
      </div>
      <div class="row justify-content-center mt-5" v-if="isLoading">
        <Spinner />
      </div>
      <div class="col-10 content" v-else>
        <div class="row content-icon ">
          <div v-if="tarotista !== null" class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
          <Descripcion
            @durationchanged="changeMinutesCall"
            @priceChanged="priceChangedCall"
            :tipo="tipo"
            :tarotista="tarotista"
            :canal="canal"
            :isLoggedIn="isLoggedIn"
          />
        </div>
        <div class="col-12 arrow-v">
              <div class="col-triangulo-v text-center">
                <div class="triangulo-one-v"></div>
              </div>
          </div>
        <div class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
          <MetodoPago
            v-if="isLoggedIn"
            :tipo="tipo"
            :existe="existe"
            :tarotistaId="tarotistaId"
            :correo='correo_user'
            :nombre='nombre_user'
            :canal="canal"
            :tarotista="tarotista"
            v-on:metodoPagoChange="
              (newMethod) => {
                metodoPago = newMethod;
              }
            "
            v-on:registroUse="registroUse"
            :duration="duration"

          />
          <DatosPersonales
            :tipo="tipo"
            :existe="existe"
            :tarotistaId="tarotistaId"
            v-on:metodoPagoChange="
              (newMethod) => {
                metodoPago = newMethod;
              }
            "
            v-on:registroUseInvitado="registroUseInvitado"
            v-on:valorLoginFunc="valorLoginFunc"
            :duration="duration"
            v-else
          />
          <ModalDatos v-if="metodoPago === 'Credit' && info == 1" />
        </div>
        <div class="col-12 arrow-v">
              <div class="col-triangulo-v text-center">
                <div class="triangulo-one-v"></div>
              </div>
          </div>
        <div class="col-lg-4 col-12 mb-lg-0 mb-3 padding-card">
          <DatosDivinoSaldo
            :tarotista="tarotista"
            :minutos="duration"
            :canal="canal"
            :existe="existe"
            :tipo="tipo"
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-on:registroMod="registroMod"
            v-if="metodoPago === 'Balance' && tarotista"
          />

          <DatosTarjeta
            :cardSaved="duration == 0 ? cardSaved : false"
            :lastDigits="lastDigits"
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-on:tarjetaDeleted="
              cardSaved = false;
              lastDigits = null;
            "
            v-if="metodoPago === 'Credit' && isLoggedIn"
          />
          <DatosTarjetaNotLogin
            :cardSaved="duration == 0 ? cardSaved : false"
            :lastDigits="lastDigits"
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-on:tarjetaDeleted="
              cardSaved = false;
              lastDigits = null;
            "
            v-if="metodoPago === 'Credit' && !isLoggedIn"
          />
          <Comodin
            v-if="metodoPago === 'Comodin' && !isLoggedIn"
          />
          <DatosTarjetaDebito
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-if="metodoPago === 'Debit'"
          />

          <DatosWebPay
            :existe="existe"
            :tipo="tipo"
            :guestId=$store.state.guestId
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-on:registroMod="registroMod"
            v-on:registroModInvitado="registroModInvitado"
            v-if="metodoPago === 'transbank'"
          />
          <DatosTransferencia
            :existe="existe"
            :tipo="tipo"
            v-on:askForConfirmPayment="askForConfirmPayment"
            v-on:registroMod="registroMod"
            v-if="metodoPago === 'Transfer'"
          />
        </div>
        </div>
        <div class="row justify-content-center content-arrow">
        <div class="col-lg-1 col-md-2 text-center">
          <div class="row">
            <div class="col col-triangulo">
              <div class="triangulo-one"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-4 mx-lg-0 mx-md-2">

        </div>

        <div class="col-lg-1 col-md-2 text-center " style="padding-left: 38px;">
          <div class="row">
            <div class="col col-triangulo">
              <div class="triangulo-one"></div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <ModalConfirmacion
        v-on:confirmPayment="confirmPayment"
        :idTarotista="tarotistaId"
        :triggerModal="modalTriggered === 'modalConfirmacion'"
      />
      <ModalEspera
        :triggerModal="modalTriggered === 'modalEspera'"
        :forceClose="forceClose == 'modalEspera'"
      />
      <ModalPagoExitoso
        :triggerModal="modalTriggered === 'modalPagoExitoso'"
        :paymentMethod="metodoPago"
        :balance="this.$store.state.balance"
        :tipo="tipo"
      />
      <ModalPagoFallo :triggerModal="modalTriggered === 'modalPagoFallo'" />
      <ModalDatosLlamada
        :triggerModal="modalTriggered === 'modalDatosLlamada'"
        v-on:setPhoneNum="numeroTelefono = $event"
        v-on:askForConfirmation="triggerModal('modalConfirmacion')"
      />
      <ModalLlamadaConfirmada
        :triggerModal="modalTriggered === 'modalLlamadaConfirmada'"
        :tarotistaName="tarotista ? tarotista.alias : ''"
        :paymentMethod="metodoPago"
        :balance="this.$store.state.balance"
        :tipo="tipo"
      />
      <ModalLlamadas />
      <ModalVideosChat/>
      <Login
          v-on:triggerModal="triggerModal"
          :triggerModal="modalTriggered === 'login'"
      />
    </div>
  </div>
</template>

<script>
import Descripcion from './Descripcion';
import MetodoPago from './MetodoPago';
import DatosDivinoSaldo from './DatosDivinoSaldo';
import DatosTarjeta from './DatosTarjeta';
import DatosTarjetaNotLogin from './DatosTarjetaNotLogin.vue'
import Comodin from './Comodin.vue'
import DatosTarjetaDebito from './DatosTarjetaDebito';
import DatosWebPay from './DatosWebPay';
import DatosTransferencia from './DatosTransferencia.vue';
import DatosPersonales from './DatosPersonales.vue';
import Spinner from '../loadingspinner/Spinner.vue'
import axiosClient from '../../config/axiosClient';

import {
  requestKushkiTokenCredit,
  requestKushkiTokenDebit,
  requestKushkiTokenTransfer,
} from '../../payment/kushki';

// import initPayment from '../../payment/initPayment';


import { finishPayment } from '../../payment/kushki/CreditCard';
import {
  getCallbackUrl,
  checkDebitPayment,
  checkDebitPaymentWebpay,
} from '../../payment/kushki/DebitCard';
import {
  getCallbackUrlTransfer,
  //checkDebitPaymentTransfer,
} from '../../payment/kushki/Transfer';

import {
  requestKushkiTokenCreditSubscription,
  saveCard,
  saveCardGuest,
  getCard,
} from '../../payment/kushki/CreditCardSubscription';

import ModalConfirmacion from './modals/ModalConfirmacion';
import ModalEspera from './modals/ModalEspera';
import ModalPagoExitoso from './modals/ModalPagoExitoso';
import ModalPagoFallo from './modals/ModalPagoFallo.vue';
import ModalDatosLlamada from './modals/ModalDatosLlamada.vue';
import ModalLlamadaConfirmada from './modals/ModalLlamadaConfirmada.vue';

import { siteUrl, ssl, enableLogs, celcomKushkiMerchantId, isProduction } from '../../config';

const { Kushki } = require('@kushki/js')


//import { WebpayPlus } from 'transbank-sdk';

//const WebpayPlus = require("transbank-sdk").WebpayPlus; // CommonJS
//const { Options, IntegrationApiKeys, Environment, IntegrationCommerceCodes } = require("transbank-sdk"); // CommonJS

//import { WebpayPlus } from 'transbank-sdk'; // ES6 Modules
//import { Options, IntegrationApiKeys, Environment, IntegrationCommerceCodes } from 'transbank-sdk'; // ES6 Modules

//const WebpayPlus = require("transbank-sdk").WebpayPlus;
//const asyncHandler = require("../utils/async_handler");


//import axios from "axios";

import ModalDatos from '../util/ModalDatos.vue'
import Login from "@/components/login/Login.vue";
import ModalLlamadas from "@/components/catalogo/modals/ModalLlamadas.vue";
import ModalVideosChat from "@/components/catalogo/modals/ModalVideosChat.vue";
export default {
  name: 'Compra',
  components: {
    ModalVideosChat, ModalLlamadas,
    Login,
    Descripcion,
    MetodoPago,
    DatosDivinoSaldo,
    DatosTarjeta,
    DatosTarjetaDebito,
    DatosWebPay,
    DatosTransferencia,
    DatosPersonales,
    ModalConfirmacion,
    ModalEspera,
    ModalPagoExitoso,
    ModalPagoFallo,
    ModalDatosLlamada,
    ModalLlamadaConfirmada,
    Spinner,
    ModalDatos,
    DatosTarjetaNotLogin,
    Comodin
  },
  data: () => {
    return {
      mode: null,
      url: null,
      id_registro_fecha: null,
      startDate: null,
      existe: false,
      tarotistaId: null,
      tipo: null,
      tarotista: null,
      canal: null,
      metodoPago: null,

      modalTriggered: null,
      forceClose: null,

      paymentData: {},

      numeroTelefono: null,
      roomId: null,
      duration: 15,
      roomPrice: 0,

      cardSaved: false,
      lastDigits: null,
      idSubscripcion: null,
      isLoading: true,

      info:null,

      f: false,
      correo_inv: "",
      correo_user: null,
      nombre_user: null,
    };
  },
  watch: {

    token: function (jwtToken) {
      if (jwtToken === null) return;

      if(enableLogs) console.log('Got token!');

      console.log('entramos token!')
      if (
        this.$route.query.token &&
        this.$route.query.roomId &&
        this.$route.query.mode
      ) {
        if (this.$route.query.mode == 'kushkiDebitCard') {
          if(enableLogs) console.log('Confirming debit payment!');

          if(enableLogs) console.log(this.$route.query);
          let kushkiToken = this.$route.query.token;
          let roomId = this.$route.query.roomId;

          this.confirmDebitRoom(kushkiToken, roomId);
        } else if (this.$route.query.mode == 'kushkiTransfer') {
          if(enableLogs) console.log('Confirming Transfer payment!');
          if(enableLogs) console.log(this.$route.query);
          let kushkiToken = this.$route.query.token;
          let roomId = this.$route.query.roomId;
          this.confirmTrasferRoom(kushkiToken, roomId);

        } else if (this.$route.query.mode == 'transbank') {
          console.log('Confirming webpay payment!');
          console.log('Entramos: '+this.$route.query.mode);

          let kushkiToken = this.$route.query.token;
          let roomId = this.$route.query.roomId;

          console.log("Token: "+kushkiToken);
          console.log("Room ID: "+roomId);

          this.confirmWebPay(kushkiToken, roomId);
        }
      }
    },
    guestId: function (guestId) {
      console.log('Antes Entramos guestID: '+guestId);
      if (guestId) {
        console.log('Entramos guestID: '+guestId);
        console.log('Entramos: '+guestId);
        if(enableLogs) console.log('Got guestId!');

        if (
          this.$route.query.token &&
          this.$route.query.roomId &&
          this.$route.query.mode
        ) {
          if (this.$route.query.mode == 'kushkiDebitCard') {

            if(enableLogs) console.log('Confirming debit payment!');
            if(enableLogs) console.log(this.$route.query);
            let kushkiToken = this.$route.query.token;
            let roomId = this.$route.query.roomId;
            this.confirmDebitRoom(kushkiToken, roomId);

          } else if (this.$route.query.mode == 'kushkiTransfer')  {
           console.log('Confirming debit payment!');
            if(enableLogs) console.log(this.$route.query);
            let kushkiToken = this.$route.query.token;
            let roomId = this.$route.query.roomId;
            this.confirmTrasferRoom(kushkiToken, roomId);
          } else if (this.$route.query.mode == 'transbank') {
             console.log('Entramos: '+this.$route.query.mode);

              let kushkiToken = this.$route.query.token;
              let roomId = this.$route.query.roomId;

            console.log("Token: "+kushkiToken);
            console.log("Room ID: "+roomId);

            this.confirmWebPay(kushkiToken, roomId);

          if(enableLogs) console.log('Confirming webpay payment!');
          //if(enableLogs) console.log(this.$route.query);
          //let kushkiToken = this.$route.query.token;
          //let roomId = this.$route.query.roomId;
          //this.confirmTrasferRoom(kushkiToken, roomId);
        }
        }
      }
    },
  },
  computed: {
    token: function () {
      return this.$store.state.jwt;
    },
    isLoggedIn: function () {
      return this.$store.state.isLoggedIn;
    },
    guestId: function () {
      return this.$store.state.guestId;
    },
  },
  created() {
    if (this.$route.query.mode) {
      if(enableLogs) console.log('Coming back from webpay');
      if (this.$route.query.mode == 'kushkiDebitCard') {
        this.metodoPago = 'Debit';
      } else {
        this.metodoPago = 'Transfer';
      }

      this.$route.query.phoneNum
        ? (this.numeroTelefono = this.$route.query.phoneNum)
        : null;
    }
    // Obtener los datos del tarotista
    this.tarotistaId = this.$route.query.tarotista;
    this.tipo = this.$route.query.tipo;
    this.canal = this.$route.query.canal;

    if (this.tarotistaId !== null && this.tarotistaId !== undefined)
      // Peticion al back para obtener la informaciÃ³n del tarotista
      this.getTarotReaderInfo();

    this.handleSavedCard();

    this.info = window.localStorage.getItem('info')
    if(this.info != '1' && this.info != null){
      this.$store.commit('setInfoCredit', this.info)
    }

  },
  mounted() {
    this.obtenerUrl();
     console.log('mounted');
    let queryParams = this.$route.query;
     console.log(queryParams);
    this.datosUser();
    if (
      this.$route.query.token &&
      this.$route.query.roomId &&
      this.$route.query.mode
    ) {
      this.metodoPago = this.$route.query.mode;
      console.log('recibimimos parametros de webpay');
      this.triggerModal('modalEspera');
      if(enableLogs) console.log('Checking room payment, waiting for jwt...');
    }
  },
  methods: {
    mostrarModal(canal){
      if (canal === 'Voz') {
        this.$bvModal.show('modalLlamadas');
      }else {
        this.$bvModal.show('modalVideosChat');
      }
    },
  /*  closeModalLlamada(){
      this.$router.push(
          `/compra?tarotista=${this.tarotReaderId}&tipo=${this.type}&canal=${this.channel}`
      );
    },
    closeModalVideosChat(){
      this.$router.push(
          `/compra?tarotista=${this.tarotReaderId}&tipo=${this.type}&canal=${this.channel}`
      );
    },*/
    priceChangedCall(price){
      this.roomPrice = price;
    },
    trackPurchaseEvent() {
      if (this.$route.query.mode === "transbank"){
        this.mode = "Web-Pay" 
      }else {
        this.mode = "Divino-Saldo"
      }
      const eventParams = {
        transaction_id: this.$route.query.roomId,
        value: this.roomPrice,
        currency: "CLP",
        items: [
          {
            item_id: this.$route.query.tarotista,
            item_name: "Compra de Lectura tipo " + this.canal + ", mediante " + this.mode,
            affiliation: "divino tarot",
            price: this.roomPrice,
            quantity: 1
          }
        ]
      };
      // Lógica para enviar el evento a Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: this.roomPrice,
          currency: "CLP",
          transaction_id: this.$route.query.roomId,
        });
      } else {
        console.error("Facebook Pixel no está cargado");
      }

      // Evento de compra
      this.$gtag.event('purchase', eventParams);

      // Evento de conversión
      this.$gtag.event('conversion_event_purchase', eventParams);
    },
    async valorLoginFunc(){
        await this.triggerModal('login')
    },
    obtenerUrl(){
      // Obtener la URL actual
      const urlActual = window.location.href;

      // Crear un elemento "a" oculto para parsear la URL
      const a = document.createElement('a');
      a.href = urlActual;

      // Obtener la parte de la URL que sigue al dominio en el formato deseado
      this.url = a.pathname + a.search;
    },
    async registroUse() {
      console.log("registroUse")
      const intervalId = setInterval(async () => {
        if (this.existe) {
          clearInterval(intervalId); // Detener el intervalo cuando existe sea true
          return;
        }

        try {
          const usado = await axiosClient.post(
            '/tarot-calendly/post-registro-use',
            {
              idTarotista: this.tarotistaId,
            },
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
          );
          this.existe = usado.data.existe;
          this.id_registro_fecha = usado.data.id;
        } catch (error) {
          console.error('Error during registroUse:', error);
        }
      }, 3000); // Ejecutar cada 3 segundos
    },
    async datosUser() {
        try {
          const datos = await axiosClient.get(
            '/users/get-users/datos',
            {
              headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
            }
          );
          console.log(datos.data)
          this.correo_user = datos.data.correo;
          this.nombre_user = datos.data.nombre;
        } catch (error) {
          console.error('Error during registroUse:', error);
        }
    },
    async registroUseInvitado(data) {
      console.log("registroUseInvitado")
      this.correo_inv = data.correo_inv;
      const intervalId = setInterval(async () => {
        if (this.existe) {
          clearInterval(intervalId); // Detener el intervalo cuando existe sea true
          return;
        }

        try {
          const usado = await axiosClient.post(
            '/tarot-calendly/post-registro-use/invitado',
            {
              idTarotista: this.tarotistaId,
              correo_inv: data.correo_inv,
            },
          );
          this.existe = usado.data.existe;
          this.id_registro_fecha = usado.data.id;
        } catch (error) {
          console.error('Error during registroUseInvitado:', error);
        }
      }, 3000); // Ejecutar cada 3 segundos
    },
    async registroMod() {
      try {
        let fecha_evento = await axiosClient.post(
          '/tarot-calendly/post-registro-use/modificar',
          {
            idTarotista: this.tarotistaId,
          },
          {
            headers: { Authorization: `Bearer ${this.$store.state.jwt}` },
          }
        );
        this.startDate = fecha_evento.data.inicio;
      }catch (error) {
        console.error('Error al tomar la fecha de inicio por:', error);
      }
    },
    async registroModInvitado() {
      console.log("registroModInvitado")
      try {
        let fecha_evento = await axiosClient.post(
          '/tarot-calendly/post-registro-use/modificar-invitado',
          {
            idTarotista: this.tarotistaId,
            correo_inv: this.correo_inv,
          },
        );
        this.startDate = fecha_evento.data.inicio;
      }catch (error) {
        console.error('Error al tomar la fecha de inicio por:', error);
      }
    },
    async handleSavedCard() {
      const { cardSaved, lastDigits, id_subscripcion } = await getCard(this.$store.state.jwt);

      if(enableLogs) console.log(cardSaved, lastDigits);

      this.cardSaved = cardSaved;
      this.lastDigits = lastDigits;
      this.idSubscripcion = id_subscripcion
    },
    async initPayment(paymentType, guestId = null) {
      let roomType;
      this.canal === "Chat" ? (roomType = "CHAT") : null;
      this.canal === "Voz" ? (roomType = "VOICE") : null;
      this.canal === "Video" ? (roomType = "VIDEO") : null;

      let startDate;

      if(enableLogs) console.log('DURACION------>', this.duration)

      if (this.tipo === "instantanea"){
        startDate = "NOW";
      }else {
        startDate = this.startDate;
      }

      let axiosBody = {
        // Id of the tarot reader
        tarotReaderId: this.tarotistaId,
        // DuraciÃ³n de la sala en minutos
        duration: this.duration,
        // "CHAT", "VOICE", "VIDEO"
        roomType: roomType,
        // Date in POSIX (UTC+-0) or "NOW"
        startDate: startDate,
        // How will the room be paid, "KUSHKI" or "WALLET"
        paymentType: paymentType,
        // Note: the user id is pulled from the jwt
        id_registro_fecha: this.id_registro_fecha,
      };

      let response;

      if(enableLogs) console.log(guestId, axiosBody);

      if (guestId) {
        axiosBody.guestId = guestId;
          console.log('entramos guestId');
        response = await axiosClient.post("/rooms/request-room/guest", axiosBody);
        console.log('Respuesta Guest');
        console.log(response);

      } else {
        response = await axiosClient.post("/rooms/request-room", axiosBody, {
          headers: { Authorization: `Bearer ${this.jwt}` },
        });
      }
      if(enableLogs) console.log(response.data);
      return response.data.roomData;
    },
    async createRoom(roomId, guestId = null) {
      let idPay = window.localStorage.getItem('idPay');
      window.localStorage.removeItem('idPay')

      const email = window.localStorage.getItem('emailGuest');

      if (guestId)
      {
        return await axiosClient.post("/rooms/create-room/guest", {
          roomId,
          guestId,
          fundOrigin: this.fundOrigin,
          phoneNum: this ? (this.numeroTelefono ? this.numeroTelefono : null) : null,
          idPay: idPay ? idPay : null,
          email
        });
      }
      return await axiosClient.post(
        "/rooms/create-room",
        {
          roomId,
          phoneNum: this ? (this.numeroTelefono ? this.numeroTelefono : null) : null,
          fundOrigin: this.fundOrigin,
          idPay: idPay ? idPay : null,
          f: this.f
        },
        {
          headers: { Authorization: `Bearer ${this.jwt}` },
        }
      );
    },
    async sendPurchaseDetails(email, purchaseDetails) {
      if(enableLogs) console.log(email);
      if(enableLogs) console.log('purchase Details', purchaseDetails);
      try {
        await axiosClient.post('payment/send-purchase-details', {
          email,
          purchaseDetails,
        });
        if(enableLogs) console.log('successfully sent email');
      } catch (error) {
        if(enableLogs) console.log(error);
      }

    },
    getNextLink(roomId) {
      let nextLink = ``;
      switch (this.canal) {
        case 'Video':
          nextLink = `/sala-espera?roomId=${roomId}`;
          if (this.$store.state.guestId) {
            nextLink += `&idInv=${this.$store.state.guestId}`;
          }
          break;
        case 'Chat':
          nextLink = `/chat?roomId=${roomId}`;
          if (this.$store.state.guestId) {
            nextLink += `&idInv=${this.$store.state.guestId}`;
          }
          break;
        case 'Voz':
          nextLink = `/room-llamada?roomId=${roomId}`;
          break;
      }
      return nextLink;
    },
    changeMinutesCall(min) {
      this.duration = min;
    },
    async askForConfirmPayment(paymentData) {
      if (this.$store.state.socket && this.tipo == "instantanea") {
        this.$store.state.socket.emit(
          'pre-buy-tarot-reader',
          this.tarotistaId,
          () => {
            if(enableLogs) console.log('TAROTISTA NOTIFICADO');
          }
        );
      }
      this.paymentData = paymentData;
      if (this.canal === 'Voz') {
        this.triggerModal('modalDatosLlamada');
        return;
      }
      this.triggerModal('modalConfirmacion');
    },
    async saveCreditCard() {
      let { personName, cardNum, expiration, cvv } = this.paymentData;

      const token = await requestKushkiTokenCreditSubscription(
        personName,
        cardNum,
        cvv,
        expiration.split('/')[0],
        expiration.split('/')[1]
      );

      if (!this.$store.state.guestId) {
        const {subscriptionId} = await saveCard(token, cardNum.slice(-4), this.$store.state.jwt);
        this.subscriptionId = subscriptionId;
        this.idSubscripcion = subscriptionId;
        this.cardSaved = true;
        this.lastDigits = cardNum.slice(-4);
      }else{
        const obj = await saveCardGuest(token, cardNum.slice(-4), this.$store.state.guestId, this.$store.state.socket.id);
        console.log(obj);
        this.subscriptionId = obj.subscriptionId;
        this.isLoading = false;
      }
    },
    async reserveFunds() {
      try {
        this.jwt = this.$store.state.jwt;
        let iPayment = this.initPayment;
        let roomId, roomPrice;
        if (this.$store.state.guestId) {
          ({ roomId, roomPrice } = await iPayment(
            'KUSHKI',
            this.$store.state.guestId
          ));
        } else {
          ({ roomId, roomPrice } = await iPayment('KUSHKI'));
        }
        if(enableLogs) console.log(`Succesfully requested room creation, roomId: ${roomId}`);
        if(enableLogs) console.log(`Reserve funds`);

        if(!this.$store.state.guestId){

          let info = JSON.parse(window.localStorage.getItem('info'));
          info = {
            lastName: info.apellido ? info.apellido : info.lastName,
            firstName: info.nombre ? info.nombre : info.firstName,
            email: info.email,
            siteDomain: "www.divinotarot.com",
            billingDetails: {
              name: (info.nombre ? info.nombre : info.firstName) + " " + (info.apellido ? info.apellido : info.lastName),
              phone: info.telefono ? info.telefono : info.phoneNumber,
              address: info.direccion ? info.direccion : (info.adress ? info.adress : info.address),
              city: info.ciudad ? info.ciudad : info.city,
              region: info.region,
              country: info.pais ? info.pais : info.country
            },
          }

          let kushki = new Kushki({
              merchantId: celcomKushkiMerchantId,
              inTestEnvironment: !isProduction,
              regional: false,
          })

          kushki.requestTokenCharge({
              subscriptionId: this.idSubscripcion // Replace with your subscription id
          }, async (response2) => {
              if(response2.token){
                try{
                  await axiosClient.post(
                    '/payment/kushki-reserve/authorize',
                    {
                      token: response2.token,
                      amount: 11250,
                      roomId,
                      info
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${this.$store.state.jwt}`,
                      },
                    }
                  );

                  if(enableLogs) console.log(`Payment finished`);
                if(enableLogs) console.log('Create the room');

                this.fundOrigin = 'kushki-cc';
                let cRoom = this.createRoom;

                await cRoom(roomId);

                if(enableLogs) console.log('Room created and everything is done!');

                // If payment was successfull, show the modal
                  if (this.canal === 'Voz' && this.tipo === 'instantanea') {
                    //this.trackPurchaseEvent();
                    await this.startPhoneCall();
                    return;
                  } else {
                    //this.trackPurchaseEvent();
                    await this.triggerModal('modalPagoExitoso');
                  }

                this.$store.state.tokenData && this.sendPurchaseDetails(this.$store.state.tokenData.email, {
                    tarotista: this.tarotista,
                    channel: this.canal,
                    roomPrice,
                    paymentMethod: 'Credit Card',
                });

                if (this.tipo === 'instantanea') {
                  await new Promise((resolve) => setTimeout(resolve, 3000));

                  this.$router.push(this.getNextLink(roomId));
                }
                    console.log(response2.token);
                    // Submit your code to your back-end
                }catch(e){
                  this.triggerModal('modalPagoFallo');
                  console.error('Error: ',e);
                }



              } else {
              this.triggerModal('modalPagoFallo');
              console.error('Error: ',response2);
              }
          });

        }else{
          let info = JSON.parse(window.localStorage.getItem('info'));
           info = {
            lastName: info.apellido ? info.apellido : info.lastName,
            firstName: info.nombre ? info.nombre : info.firstName,
            siteDomain: "www.divinotarot.com",
            billingDetails: {
              name: (info.nombre ? info.nombre : info.firstName) + " " + (info.apellido ? info.apellido : info.lastName),
              phone: info.telefono ? info.telefono : info.phoneNumber,
              address: info.direccion ? info.direccion : (info.adress ? info.adress : info.address),
              city: info.ciudad ? info.ciudad : info.city,
              region: info.region,
              country: info.pais ? info.pais : info.country
            },
          }

          let kushki = new Kushki({
              merchantId: celcomKushkiMerchantId,
              inTestEnvironment: !isProduction,
              regional: false,
          })

          kushki.requestTokenCharge({
              subscriptionId: this.subscriptionId // Replace with your subscription id
          }, async (response2) => {
            if(response2.token){
              try{
                await axiosClient.post(
                '/payment/kushki-reserve/authorize/guest',
                {
                  token: response2.token,
                  amount: 11250,
                  roomId,
                  idGuest: this.$store.state.guestId,
                  idSocket: this.$store.state.socket.id,
                  info
                },
                {
                  headers: {},
                }
                );

                if(enableLogs) console.log(`Payment finished`);
                if(enableLogs) console.log('Create the room');

                this.fundOrigin = 'kushki-cc';
                let cRoom = this.createRoom;

                await cRoom(roomId, this.$store.state.guestId);
                if(enableLogs) console.log('Room created and everything is done!');

                // If payment was successfull, show the modal
                if (this.canal === 'Voz' && this.tipo === 'instantanea') {
                  //this.trackPurchaseEvent();
                  await this.startPhoneCall();
                  return;
                } else {
                  //this.trackPurchaseEvent();
                  await this.triggerModal('modalPagoExitoso');
                }

                //! email para invitado
                /*this.$store.state.tokenData &&
                this.sendPurchaseDetails(this.$store.state.tokenData.email, {
                  tarotista: this.tarotista,
                  channel: this.canal,
                  roomPrice,
                  paymentMethod: 'Credit Card',
                });*/

                if (this.tipo === 'instantanea') {
                  await new Promise((resolve) => setTimeout(resolve, 3000));
                  this.$router.push(this.getNextLink(roomId));
                }

              }catch(e){
                this.triggerModal('modalPagoFallo');
                console.error('Error: ',e);
              }



            }else{
              this.triggerModal('modalPagoFallo');
              console.error('Error: ',response2);
            }
          })
        }

      } catch (err) {
        if (err.response) {
          if(enableLogs) console.log(err.response.data);
        }
        if(enableLogs) console.log(err);
        this.triggerModal('modalPagoFallo');
      }
    },
    async confirmPayment() {
      try{
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
        console.log('FLAG 1')
        if(enableLogs) console.log(this.paymentData);
        if (this.paymentData) {
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          console.log('FLAG 2')
          if (this.paymentData.type === 'kushkiCreditCard') {
            console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
            console.log('FLAG 3')
            this.triggerModal('modalEspera');
            if(this.duration == 0){
              console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
              console.log('FLAG 4F')
              if (!this.cardSaved) await this.saveCreditCard();
              this.reserveFunds();
            }else{
              console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
              console.log('FLAG 4OK')
              this.payWithCreditCard()
            }
          }else if(this.paymentData.type === 'transbank'){
            console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
             console.log('FLAG Transbank');

                console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
              console.log('ENTRA THIS Transbank');
              this.triggerModal('modalEspera');
              this.payWithWebpay();
          }

          else {
            console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
            console.log('FLAG ERROR')
            if (this.paymentData.type === 'kushkiDebitCard') {
              this.triggerModal('modalEspera');
              this.payWithDebitCard();
            } else
            if (this.paymentData.type === 'balance') {
              this.triggerModal('modalEspera');
              this.payWithBalance();
            } else
            if (this.paymentData.type === 'kushkiTransfer') {
              this.triggerModal('modalEspera');
              this.payWithTransfer();
            }
          }
        }
      }catch(e){
        this.triggerModal('modalPagoFallo');
        console.error('Error: ',e);
      }
    },
    async payWithTransfer() {
      let { personEmail } = this.paymentData;
      if(enableLogs) console.log('Starting transfer payment');

      this.jwt = this.$store.state.jwt;
      let iPayment = this.initPayment;

      let roomId, roomPrice;
      if (this.$store.state.guestId) {
        ({ roomId, roomPrice } = await iPayment(
          'KUSHKI',
          this.$store.state.guestId
        ));
      } else {
        ({ roomId, roomPrice } = await iPayment('KUSHKI'));
      }
      if(enableLogs) console.log(`Succesfully requested room creation, roomId: ${roomId}`);

      if(enableLogs) console.log('Requesting kushki token');
      let kushkiToken = await requestKushkiTokenTransfer(
        roomPrice,
        'CLP',
        `${
          ssl ? 'https' : 'http'
        }://${siteUrl}/compra/?roomId=${roomId}&tarotista=${
          this.tarotistaId
        }&tipo=${this.tipo}&canal=${this.canal}&mode=${
          this.paymentData.type
        }&phoneNum=${this.numeroTelefono}`,
        personEmail,
        'DivinoTarot'
      );
      if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);

      if(enableLogs) console.log(`Get payment URL`);

      let gCallbackUrl = getCallbackUrlTransfer.bind(this);
      let response;

      if (this.$store.state.guestId) {
        response = await gCallbackUrl(
          roomId,
          kushkiToken,
          roomPrice,
          this.$store.state.guestId
        );
      } else {
        response = await gCallbackUrl(roomId, kushkiToken, roomPrice);
      }

      if(enableLogs) console.log('Got callback url, redirecting in 5 seconds...');
      // this.sendPurchaseDetails(personEmail, {
      //   tarotista: this.tarotista,
      //   channel: this.canal,
      //   roomPrice,
      //   paymentMethod: 'Transfer',
      // });
      if(enableLogs) console.log(response.data);
      window.location.href = response.data.redirectUrl;
    },
    async payWithBalance() {
      this.jwt = this.$store.state.jwt;
      if(enableLogs) console.log('Initialize payment with balance');

      // const { email } = this.$store.state.tokenData;
//este llama a request-room
      let iPayment = this.initPayment;
      let { roomId, roomPrice } = await iPayment('WALLET');
      console.log(roomId, roomPrice);

      let response = await axiosClient.post(
        'payment/virtual-wallet-room/',
        {
          roomId: roomId,
        },
        {
          headers: { Authorization: `Bearer ${this.jwt}` },
        }
      );

     console.log("respose" + response.data);
      if (response.data.code !== 2) {
        if(enableLogs) console.log(this.$store.state.balance);
        if(enableLogs) console.log('error');
        return;
      }
      this.$store.state.balance = this.$store.state.balance - roomPrice;
      if(enableLogs) console.log('Create the room');

      this.fundOrigin = 'divinosaldo';
      let cRoom = this.createRoom;

      await cRoom(roomId);
      if(enableLogs) console.log('Room created and everything is done!');

      if (this.canal === 'Voz' && this.tipo === 'instantanea') {
          this.trackPurchaseEvent();
          await this.startPhoneCall(roomId, this.numeroTelefono);
          return;
        } else {
          this.trackPurchaseEvent();
          await this.triggerModal('modalPagoExitoso');
        }

      // this.sendPurchaseDetails(email, {
      //   tarotista: this.tarotista,
      //   channel: this.canal,
      //   roomPrice,
      //   paymentMethod: 'DivinoSaldo',
      // });

      if (this.tipo === 'instantanea') {
        await new Promise((resolve) => setTimeout(resolve, 4000));
        this.mostrarModal(this.canal);
        setTimeout(() => {
          this.$router.push(this.getNextLink(roomId));
        }, 6000);
      }else {
        await new Promise((resolve) => setTimeout(resolve, 4000));
        this.$store.commit("setMessage", "Su reserva se a realizado correctamente");
        this.mostrarModal(this.canal);
        setTimeout(() => {
          this.$router.push('/');
        }, 6000);

      }
    },
    async payWithDebitCard() {
      let { personEmail } = this.paymentData;
      if(enableLogs) console.log('Starting debit card payment');

      this.jwt = this.$store.state.jwt;

      let iPayment = this.initPayment;

      let roomId, roomPrice;
      if (this.$store.state.guestId) {
        ({ roomId, roomPrice } = await iPayment(
          'KUSHKI',
          this.$store.state.guestId
        ));
      } else {
        ({ roomId, roomPrice } = await iPayment('KUSHKI'));
      }

      if(enableLogs) console.log(`Succesfully requested room creation, roomId: ${roomId}`);

      if(enableLogs) console.log('Requesting kushki token');
      let kushkiToken = await requestKushkiTokenDebit(
        roomPrice,
        'CLP',
        `${
          ssl ? 'https' : 'http'
        }://${siteUrl}/compra/?roomId=${roomId}&tarotista=${
          this.tarotistaId
        }&tipo=${this.tipo}&canal=${this.canal}&mode=${
          this.paymentData.type
        }&phoneNum=${this.numeroTelefono}`,
        personEmail,
        'DivinoTarot'
      );
      if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);

      if(enableLogs) console.log(`Get payment URL`);

      let gCallbackUrl = getCallbackUrl.bind(this);
      let response;

      if (this.$store.state.guestId) {
        response = await gCallbackUrl(
          roomId,
          kushkiToken,
          roomPrice,
          this.$store.state.guestId
        );
      } else {
        response = await gCallbackUrl(roomId, kushkiToken, roomPrice);
      }

      if(enableLogs) console.log('Got callback url, redirecting in 5 seconds...');

      // this.$store.state.tokenData &&
      //   this.sendPurchaseDetails(this.$store.state.tokenData.email, {
      //     tarotista: this.tarotista,
      //     channel: this.canal,
      //     roomPrice,
      //     paymentMethod: 'Debit Card',
      //   });
      if(enableLogs) console.log(response.data);
      console.log(response.data.redirectUrl);
      window.location.href = response.data.redirectUrl;
    },
    async payWithWebpay() {
     // let { personEmail } = this.paymentData;
      if(enableLogs) console.log('Starting debit card payment');

      this.jwt = this.$store.state.jwt;

      let iPayment = this.initPayment;

      let roomId, roomPrice;
      console.log("this.$store.state.guestId " + this.$store.state.guestId);
      if (this.$store.state.guestId) {
        console.log('Init Payment con GuestID');
        ({ roomId, roomPrice } = await iPayment(
          'WEBPAY',
          this.$store.state.guestId
        ));
      } else {
         console.log('Init Payment sin GuestID');
        ({ roomId, roomPrice } = await iPayment('WEBPAY'));
      }

      console.log('roomId:'+ roomId);
      console.log('roomPrice: '+roomPrice);

      if(enableLogs) console.log(`Succesfully requested room creation, roomId: ${roomId}`);
      let tarotista = this.$route.query.tarotista;
      let tipo = this.$route.query.tipo;
      let canal = this.$route.query.canal;
      let numeroTelefono = this.numeroTelefono;

      var data_encrypt = {tarotista:tarotista,tipo:tipo,canal:canal,roomPrice:roomPrice,id_compra:roomId, roomId:roomId,numeroTelefono:numeroTelefono};
      let responsenew = await axiosClient.post("/payment/pep-webpay/webpay",data_encrypt);

      window.location.assign((ssl ? 'https' : 'http') + '://' + siteUrl + '/form-webpay?token=' + responsenew.data.token + '&url=' + responsenew.data.url);
    },
    async confirmDebitRoom(kushkiToken, roomId) {
      await this.$nextTick();
      this.jwt = this.$store.state.jwt;

      let response;
      if (this.jwt) {
        console.log('con JWT');
        response = await axiosClient.post(
          '/payment/kushki-room-webpay/verify',
          {
            roomId: roomId,
            kushkiFrontendToken: kushkiToken,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.jwt}`,
            },
          }
        );
      } else {
         console.log('con guestId');
        response = await checkDebitPayment(
          roomId,
          kushkiToken,
          this.$store.state.guestId
        );
      }


      console.log('respuesta debit');
      console.log(response);
      console.log(response.data);
      //alert('entramos');
      if(enableLogs) console.log(response);

      if (response.data.code == 2) {
        this.f=false;
        if(enableLogs) console.log('Create the room');

        window.localStorage.setItem('idPay', response.data.idPay)
        this.fundOrigin = 'kushki-webpay';
        this.$route.query.phoneNum
          ? (this.numeroTelefono = this.$route.query.phoneNum)
          : null;

        let cRoom = this.createRoom;

        if (this.$store.state.guestId) {
          await cRoom(roomId, this.$store.state.guestId);
        } else {
          await cRoom(roomId);
        }

        if(enableLogs) console.log('Room created and everything is done!');

        if (this.canal === 'Voz' && this.tipo === 'instantanea') {
          //this.trackPurchaseEvent();
          await this.startPhoneCall();
          return;
        } else {
          //this.trackPurchaseEvent();
          await this.triggerModal('modalPagoExitoso');
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));
        if(enableLogs) console.log('Forwarding to ', this.getNextLink(roomId));

        this.$router.push(this.getNextLink(roomId));
      }else{
        this.f=true;
        await this.createRoom(roomId);
        this.$store.commit("setMessage", "No se pudo validar el pago");
        this.$router.push("/");
      }
    },

    async confirmWebPay(webPayToken, roomId) {
      await this.$nextTick();
      this.jwt = this.$store.state.jwt;

      let tokenWs = this.$route.query.token_ws;
      let guestId = this.$store.state.guestId;
      let response;

      if (!tokenWs){
        await this.triggerModal('modalPagoFallo');
        return;
      }
        response = await checkDebitPaymentWebpay(
          roomId,
          webPayToken,
          guestId,
          tokenWs,
          this.jwt
        );

      console.log("response verify");
      console.log(response.data);

      //alert('verify');

      if(enableLogs) console.log(response);

      if (response.data.code == 2) {

        console.log('response verify authorized');
        this.f=false;
        if(enableLogs) console.log('Create the room');
        console.log('idPay: '+response.data.idPay);
        window.localStorage.setItem('idPay', response.data.idPay)

        this.fundOrigin = 'transbank';
        this.$route.query.phoneNum ? (this.numeroTelefono = this.$route.query.phoneNum) : null;

        let cRoom = this.createRoom;

        if (this.$store.state.guestId) {
          console.log('Creamos Sala con GuestID');
          await cRoom(roomId, this.$store.state.guestId);
        } else {
          console.log('Creamos Sala sin GuestID');
          await cRoom(roomId);
        }


        console.log('Room created and everything is done!');

        if(enableLogs) console.log('Room created and everything is done!');

        if (this.canal === 'Voz' && this.tipo === 'instantanea') {
          this.trackPurchaseEvent();
          await this.startPhoneCall();
          return;
        } else {
          this.trackPurchaseEvent();
          await this.triggerModal('modalPagoExitoso');
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));
        if(enableLogs) console.log('Forwarding to ', this.getNextLink(roomId));

        //this.$router.push(this.getNextLink(roomId));
        if (this.tipo === 'instantanea') {
          await new Promise((resolve) => setTimeout(resolve, 4000));
          this.mostrarModal(this.canal);
          setTimeout(() => {
            this.$router.push(this.getNextLink(roomId));
          }, 6000);
        }else {
          await new Promise((resolve) => setTimeout(resolve, 4000));
          this.$store.commit("setMessage", "Su reserva se a realizado correctamente");
          this.mostrarModal(this.canal);
          setTimeout(() => {
            this.$router.push('/');
          }, 6000);
        }

      }else{
        this.f=true;
        //await this.createRoom(roomId);
        this.$store.commit("setMessage", "No se pudo validar el pago");
        this.$store.state.socket.emit('cerrarAvisoNotificacion', {
          idTarotista: this.tarotistaId,
        });
        this.$router.push("/");
      }
    },

    async confirmTrasferRoom(kushkiToken, roomId) {
      await this.$nextTick();
      this.jwt = this.$store.state.jwt;

      let response;
      if (this.jwt) {
        response = await axiosClient.post(
          '/payment/kushki-room-transfer/verify',
          {
            kushkiFrontendToken: kushkiToken,
            roomId,
          },
          {
            headers: { Authorization: `Bearer ${this.jwt}` },
          }
        );
      } else {
        response = await axiosClient.post(
            '/payment/kushki-room-transfer/verify/guest',
            {
                kushkiFrontendToken: kushkiToken,
                roomId,
                guestId: this.$store.state.guestId 
            },
            {
                headers: { Authorization: `Bearer ${this.jwt}` },
            }
        )
      }

      if(enableLogs) console.log(response);

      if (response.data.code == 2) {
        if(enableLogs) console.log('Create the room');

        window.localStorage.setItem('idPay', response.data.idPay)
        this.fundOrigin = 'kushki-webpay';
        this.$route.query.phoneNum
          ? (this.numeroTelefono = this.$route.query.phoneNum)
          : null;
        let cRoom = this.createRoom;

        if (this.$store.state.guestId) {
          await cRoom(roomId, this.$store.state.guestId);
        } else {
          await cRoom(roomId);
        }

        if(enableLogs) console.log('Room created and everything is done!');

        if (this.canal === 'Voz' && this.tipo === 'instantanea') {
          //this.trackPurchaseEvent();
          await this.startPhoneCall();
          return;
        } else {
          //this.trackPurchaseEvent();
          await this.triggerModal('modalPagoExitoso');
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));
        if(enableLogs) console.log('Forwarding to ', this.getNextLink(roomId));

        //this.$router.push(this.getNextLink(roomId));
        if (this.tipo === 'instantanea') {
          await new Promise((resolve) => setTimeout(resolve, 4000));

          this.$router.push(this.getNextLink(roomId));
        }else {
          await new Promise((resolve) => setTimeout(resolve, 4000));
          this.$store.commit("setMessage", "Su reserva se a realizado correctamente");
          this.$router.push('/');
        }
      }else{
        this.$store.commit("setMessage", "No se pudo validar el pago");
        this.$router.push("/");
      }
    },

    async payWithCreditCard() {
      console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
      console.log('FLAG 5')
      let { personName, cardNum, expiration, cvv } = this.paymentData;

      if(enableLogs) console.log('Starting credit card payment');
      try {
        this.jwt = this.$store.state.jwt;
        let iPayment = this.initPayment;
        let roomId, roomPrice;
        if (this.$store.state.guestId) {
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          console.log('FLAG 6 FFFF'),
          ({ roomId, roomPrice } = await iPayment(
            'KUSHKI',
            this.$store.state.guestId
          ));
        } else {
          ({ roomId, roomPrice } = await iPayment('KUSHKI'));
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          console.log('FLAG 6 OK')
        }
        if(enableLogs) console.log(`Succesfully requested room creation, roomId: ${roomId}`);

        if(enableLogs) console.log('Requesting kushki token');
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
        console.log('FLAG 7 OK')
        let kushkiToken = await requestKushkiTokenCredit(
          roomPrice,
          'CLP',
          personName,
          cardNum,
          cvv,
          expiration.split('/')[0],
          expiration.split('/')[1]
        );
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
        console.log('FLAG 9 - '+kushkiToken)
        if(enableLogs) console.log(`Succesfully requested kushki token: ${kushkiToken}`);
        if(enableLogs) console.log(`Finish the payment`);
        let fPayment = finishPayment.bind(this);

        let response = null;

        if (this.$store.state.guestId) {

        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
        console.log('FLAG 10 FFFF')

          response = await fPayment(
            roomId,
            kushkiToken,
            roomPrice,
            this.$store.state.guestId,
            this.token
          );
        } else {
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          console.log('FLAG 10 OK')
          response = await fPayment(roomId, kushkiToken, roomPrice);
        }

        try{
          console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
          console.log('FLAG FIN')
          if(response.code == 2){
            window.localStorage.setItem('idPay', response.idPay)
          }
        }catch(e){e}

        if(enableLogs) console.log(`Payment finished`);

        if(enableLogs) console.log('Create the room');

        this.fundOrigin = 'kushki-cc';
        let cRoom = this.createRoom;

        if (this.$store.state.guestId) {
          await cRoom(roomId, this.$store.state.guestId);
        } else {
          await cRoom(roomId);
        }
        if(enableLogs) console.log('Room created and everything is done!');

        // If payment was successfull, show the modal
        if (this.canal === 'Voz' && this.tipo === 'instantanea') {
          //this.trackPurchaseEvent();
          await this.startPhoneCall();
          return;
        } else {
          //this.trackPurchaseEvent();
          await this.triggerModal('modalPagoExitoso');
        }

        if(enableLogs) console.log('EJE CUTE LA FUNCION: payWithCreditCard');

        // this.$store.state.tokenData &&
        //   this.sendPurchaseDetails(this.$store.state.tokenData.email, {
        //     tarotista: this.tarotista,
        //     channel: this.canal,
        //     roomPrice,
        //     paymentMethod: 'Credit Card',
        //   });

        if (this.tipo === 'instantanea') {
          await new Promise((resolve) => setTimeout(resolve, 3000));

          this.$router.push(this.getNextLink(roomId));
        }
      } catch (err) {
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
        console.log('FLAG CATCH')
        console.log(err)
        if(enableLogs) console.log('Error with credit card payment');
        if (err.response) {
          if(enableLogs) console.log(err.response.data);
        }
        if(enableLogs) console.log(err);
        this.triggerModal('modalPagoFallo');
      }

      await this.$nextTick();
    },
    async triggerModal(modal) {
    console.log(`Show Modal: ${modal}`);
      switch (modal) {
        case 'login':
          this.modalTriggered = 'login';
          break;

        case 'modalConfirmacion':
          this.modalTriggered = 'modalConfirmacion';
          break;
        case 'modalEspera':
          this.modalTriggered = 'modalEspera';
          break;

        case 'modalPagoExitoso':
          this.modalTriggered = 'modalPagoExitoso';
          this.forceClose = 'modalEspera';
          break;
        case 'modalPagoFallo':
          this.modalTriggered = 'modalPagoFallo';
          this.forceClose = 'modalEspera';
          break;
        case 'modalDatosLlamada':
          this.modalTriggered = 'modalDatosLlamada';
          break;
        case 'modalLlamadaConfirmada':
          this.modalTriggered = 'modalLlamadaConfirmada';
          break;
      }
      await this.$nextTick();
      this.modalTriggered = null;
      this.forceClose = null;
    },
    async startPhoneCall(roomId, num) {
      if (this.$route.query.roomId) this.roomId = this.$route.query.roomId;
      this.$store.state.socket.emit(
        'request-tarot-reader',
        this.roomId ? this.roomId : roomId,
        'VOICE',
        () => {}
      );
      if (this.$route.query.mode) {
        if(enableLogs) console.log('Coming back from webpay');
        if (this.$route.query.mode == 'kushkiDebitCard') {
          this.metodoPago = 'Debit';
         }else if(this.$route.query.mode == 'transbank') {
          console.log('entre transbank pago llamada');
          this.metodoPago = 'Debit';
       
        } else {
          this.metodoPago = 'Transfer';
        }
        this.$route.query.phoneNum
          ? (this.numeroTelefono = this.$route.query.phoneNum)
          : num;
      }
      this.triggerModal('modalLlamadaConfirmada');
    },
    async getTarotReaderInfo() {
      if(enableLogs) console.log('Fetching tarot reader info');
      try {
        let response = await axiosClient.get(
          `/tarot-reader/get-tarot-reader-info/${this.tarotistaId}`
        );
        this.tarotista = response.data.tarotReaderInfo;

        if(enableLogs) console.log('Finished fetching tarot reader info');
      } catch (err) {
        if(enableLogs) console.log('Error fetching tarot reader info');
      }
          this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.submenu {
  padding-top: 60px;
}
.title {
  text-align: center;
}
.padding-card{
  padding: 0 18px !important;
}
/*triangulo*/
.content {
  position: relative;
}
.content-icon {
  position: absolute;
}
.content-arrow {
  margin: 208px 0;
}
.triangulo-one {
  width: 0;
  height: 0;
  border-left: 10px solid #979797;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin:auto;
}
.col-triangulo-v {
  max-width: 16px;
  padding: 0;
  margin-bottom: 16px;
  margin-left: calc(50% - 6.5px);
}
.triangulo-one-v {
  width: 0;
  height: 0;
  border-left: 10px solid #979797;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transform: rotate(90deg);
}
.arrow-v{
  display: none;
}
@media screen and (max-width: 992px) {
    .content-icon {
    position: relative;
    }
    .content-arrow {
    display: none;
    }
    .arrow-v{
      display: block;
    }
  }
</style>
